import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [];

export const dictionary = {
		"/": [12],
		"/(app)/account-creation-pending": [13,[2]],
		"/(app)/activate": [14,[2]],
		"/(app)/benefits": [15,[2]],
		"/(auth)/biometric-unlock": [40,[11]],
		"/(app)/card/[walletId]/add-funds": [18,[2,3]],
		"/(app)/card/[walletId]/dashboard": [19,[2,3]],
		"/(app)/card/[walletId]/(card)/settings": [16,[2,3,4]],
		"/(app)/card/[walletId]/(card)/settings/pin-reveal": [17,[2,3,4]],
		"/(app)/card/[walletId]/transaction/[transactionId]": [20,[2,3]],
		"/(app)/card/[walletId]/withdraw": [21,[2,3]],
		"/(app)/check-account-state": [22,[2]],
		"/(auth)/claim-invite": [41,[11]],
		"/(app)/dashboard": [23,[2]],
		"/(app)/dashboard/[walletId]": [24,[2,5]],
		"/(app)/enable-biometrics": [25,[2]],
		"/(app)/exchange-money": [26,[2]],
		"/(auth)/forgot-password": [42,[11]],
		"/(app)/new-beneficiary": [27,[2]],
		"/(app)/new-recipient": [28,[2]],
		"/outage": [~48],
		"/(app)/recipients": [29,[2,6]],
		"/(app)/recipients/[beneficiaryId]": [30,[2,6,7]],
		"/(auth)/reset-password": [43,[11]],
		"/(app)/salary-rules": [~31,[2]],
		"/(app)/salary-rules/recipients": [~32,[2]],
		"/(app)/salary-rules/recipients/[recipientId]": [~33,[2],[,8]],
		"/(app)/self-onboard": [34,[2]],
		"/(app)/send": [35,[2,9]],
		"/(app)/send/[beneficiaryId]": [36,[2,9,10]],
		"/(app)/send/[beneficiaryId]/fix": [37,[2,9,10]],
		"/(auth)/sign-in": [44,[11]],
		"/(auth)/support": [45,[11]],
		"/(auth)/support/unhandled_challenge": [46,[11]],
		"/(app)/update-compliance-details": [38,[2]],
		"/(app)/useful-links": [39,[2]],
		"/(auth)/welcome": [47,[11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';